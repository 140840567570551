import { useEffect, useState } from 'react'

import { toSnakeCase } from '~/utils/to-snake-case'
import { Website_Section } from '~/website.models'

export function Subnav({ sections }: { sections: Website_Section[] }) {
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const handleClick = (id: string) => {
    setActiveSection(id)
    const element = document.getElementById(id)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 60, // 100px offset
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (target.closest('.main-navigation')) {
        setActiveSection(null)
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return sections.length ? (
    <>
      <div className="page-nav">
        {sections.map((section, index) =>
          section?.title ? (
            <button
              key={index}
              onClick={() =>
                section.title ? handleClick(toSnakeCase(section.title)) : void 0
              }
              className={`page-nav-item ${activeSection === toSnakeCase(section.title) ? 'active' : ''}`}
            >
              {section.title}
            </button>
          ) : null,
        )}
      </div>
    </>
  ) : null
}
