import classNames from 'classnames'
import xss from 'xss'

import { Carousel } from './carousel'
import { Sections } from './sections'
import { Subnav } from './subnav'
import { toSnakeCase } from '~/utils/to-snake-case'
import {
  Website_Block,
  Website_Config,
  Website_Event,
  Website_Page,
} from '~/website.models'

function formatDate(dateStr: string): string {
  const [year, month, day] = dateStr.split('-')
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  return `${months[parseInt(month) - 1]} ${day}, ${year}`
}

function formatTime(timeStr: string): string {
  const [hour, minute] = timeStr.split(':')
  const period = parseInt(hour) >= 12 ? 'PM' : 'AM'

  const hourString = (parseInt(hour) % 12 || 12).toString()

  return `${hourString}:${minute} ${period}`
}

export function Page({
  blocks,
  config,
  events,
  page,
  static_content,
}: {
  blocks?: Website_Block[]
  config?: Website_Config[]
  events?: Website_Event[]
  page?: Website_Page
  static_content?: string
}) {
  const sections = page?.sections ?? []
  const heroImages = page?.hero_images ?? []

  const locationHtml =
    blocks?.find(block => block.type === 'location')?.html ?? ''

  const hoursHtml = blocks?.find(block => block.type === 'hours')?.html ?? ''

  return (
    <>
      {heroImages.length > 0 ? (
        <Carousel heroImages={heroImages} />
      ) : (
        <div className="header-push"></div>
      )}

      {page?.type === 'home' && config?.[0]?.message_enabled && (
        <div className="message">{config?.[0]?.message_html}</div>
      )}

      {page?.show_sub_nav ? <Subnav sections={sections}></Subnav> : null}

      {page?.type === 'contact' && (
        <div className="section contact">
          <h1 className="above center">Location &amp; Hours</h1>

          <div className="container split">
            <div className="section-col">
              <span
                dangerouslySetInnerHTML={{ __html: xss(locationHtml) }}
              ></span>
            </div>

            <div className="section-col">
              <span dangerouslySetInnerHTML={{ __html: xss(hoursHtml) }}></span>
            </div>
          </div>
        </div>
      )}

      <Sections sections={sections} />

      {page?.type === 'events' && events
        ? [...events].map((e, index) => {
            const formattedDate = e.start_date ? formatDate(e.start_date) : ''
            const formattedTime = e.start_time ? formatTime(e.start_time) : ''

            return (
              <div
                key={index}
                id={e.title ? toSnakeCase(e.title) : undefined}
                className={classNames('section events', {
                  alt: index % 2 === 0,
                })}
              >
                <div className="container split">
                  {e.image_url ? (
                    <div className="section-col">
                      <img
                        className="section-img"
                        src={e.image_url}
                        alt={e.title}
                        style={
                          e.image_radius
                            ? { borderRadius: e.image_radius + 'px' }
                            : {}
                        }
                      />
                    </div>
                  ) : null}

                  <div className="section-col">
                    <h1>{e.title}</h1>
                    <div className="blurb-date">
                      {formattedDate ? formattedDate : null}
                      {formattedTime ? ` @ ${formattedTime}` : null}
                    </div>

                    <div
                      className="blurb"
                      dangerouslySetInnerHTML={{
                        __html: xss(e.description || ''),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )
          })
        : null}

      {static_content ? (
        <div className="page-content">
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: static_content }}
          ></div>
        </div>
      ) : null}
    </>
  )
}
