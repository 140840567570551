import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Website_Hero_Image } from '~/website.models'

import 'swiper/css'

export function Carousel({ heroImages }: { heroImages: Website_Hero_Image[] }) {
  return (
    <Swiper
      modules={[Autoplay, EffectFade, Pagination]}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      effect={'fade'}
      loop={Boolean(heroImages.length > 1)}
      pagination={{ clickable: true }}
      slidesPerView={1}
    >
      {heroImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.url} alt={image.title} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
